input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

.costInput {
	width: 100px;
	vertical-align: center;
}

.OrderProductRow {
	margin-bottom: 4px;
}
